import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/master.scss';

const NotFound = () => (
  <Fragment>
    <Helmet>
      <title>404. That's an error.</title>
    </Helmet>
    <div className="min-h-screen min-w-full prose dark:prose-invert bg-white dark:bg-slate-900">
      <div className="container mx-auto p-8 md:p-32">
        <header>
          <h1>404. That's an error.</h1>
        </header>
        <main>
          <p className="md:prose-xl">
            The requested URL was not found on this server.
          </p>
        </main>
        <footer>
          <p className="mt-2">That's all we know.</p>
        </footer>
      </div>
    </div>
  </Fragment>
);

export default NotFound;
